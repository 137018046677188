<template>
  <div class="box">
    <div class="head">
      <div class="title">Furry Legends Cat</div>
      <div class="author">Powered by solana</div>
    </div>
    <div class="container">
      <div class="left">
        <img src="@/assets/image/mk/cat.gif" alt="" />
      </div>
      <div class="right">
        <img src="@/assets/image/mk/right.png" alt="" />
      </div>
    </div>
    <div class="footer">
      <div>COPYRIGHT © 2024 <span>Furry Legends Cat</span>. ALL RIGHTS RESERVED</div>
      <div>
        <a :href="$config.twitterUrl" target="_Twitte">
          <img src="@/assets/image/bscm/Twitte.png" alt="" />
        </a>
        <a :href="$config.tgUrl" target="_Telegram">
          <img src="@/assets/image/bscm/Telegram.png" alt="" />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<!-- Demo styles -->
<style lang="scss" scoped>
.head {
    text-align: center;
    padding-top: 100px;
    .title{
        color: #213cd2;
        text-shadow: 1px 1px #000000;
    }
    .author{
        color: #999999;
        font-family: "Harmony";
    }
}
.container {
display: flex;
justify-content: space-between;
align-items: center;
.right{
    background-color: #fff;
}
}
.footer {
  background-color: #252526;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  color: #999999;
  font-family: "Harmony";
  font-weight: 400;
  font-size: 14px;
  span{
    color: #fbbd15;
  }
  img{
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
.box {
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
}
</style>
